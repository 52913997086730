var nhwJS = ( function( nhw ) {
    'use strict';
    
    var _debug = false;
    
    nhw.lightbox = {
    	init: function() {
            if ( _debug ) {
                console.log( 'Initializing: nhwJS.lightbox.init' );
            }
            
            $( 'body' ).on( 'click', '.bordered-image img', function() {
                var img = $( this );

                $( 'body' ).append( _setupLightbox( img ) )

                _setupLightboxHandler();
            } );
        }
    };
    
    function _setupLightbox( img ) {
        if ( _debug ) {
            console.log( 'EXECUTE: _setupLightbox' );
            console.log( '--> img: ', img );
        }

        // setup lightbox
        var lb = $( '<div />' ).addClass( 'lightbox' );
        var lbWrapper = $( '<div />' ).addClass( 'lightbox__wrapper' );
        var lbTitle = $( '<div />' ).addClass( 'lightbox__title' ).text( img.attr( 'alt' ) );
        var lbImage = $( '<img />' ).attr( 'src', img.attr( 'src' ) ).attr( 'alt', img.attr( 'alt' ) );

        // build lightbox
        lbWrapper.append( lbTitle ).append( lbImage );
        lb.append( lbWrapper );

        return lb;
    }

    function _setupLightboxHandler() {
        if ( _debug ) {
            console.log( 'EXECUTE: _setupLightboxHandler' );
        }

        var lbHeight = $( '.lightbox__wrapper' ).outerHeight() / 2;

        $( '.lightbox__wrapper' ).css( {
            'margin-top': '-' + lbHeight + 'px'
        } );

        $( 'body' ).on( 'click', '.lightbox', function () {
            $( '.lightbox' ).remove();
        } );
    }
    
    return nhw;
    
} )( nhwJS || {}, jQuery );