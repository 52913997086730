var nhwJS = ( function() {
    'use strict';
    
    var _debug = false;
    var _defaults = {};
    
    var nhw = {};
    
    nhw.init = function( config ) {
        if ( _debug ) {
            console.log( 'Initializing: nhw.init' );
            console.log( '--> config = ', config );
        }
        
        $.extend( true, _defaults, config );

        // init menu
        nhwJS.menu.init();

        // init lightbox
        nhwJS.lightbox.init();

        // init famous last words
        nhwJS.famousLastWords.init();
    }

    return nhw;
    
} )( jQuery );