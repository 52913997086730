var nhwJS = ( function( nhw ) {
    'use strict';
    
    var _debug = false;
    var _defaults = {};
    
    nhw.module = {
    	init: function( config ) {
            if ( _debug ) {
                console.log( 'Initializing: nhw.module.init' );
                console.log( '--> config = ', config );
            }
            
            $.extend( true, _defaults, config );
        }
    };
    
    return nhw;
    
} )( nhwJS || {}, jQuery );