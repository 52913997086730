var nhwJS = ( function( nhw ) {
    'use strict';
    
    var _debug = false;
    
    nhw.menu = {
    	init: function() {
            if ( _debug ) {
                console.log( 'Initializing: nhw.menu.init' );
            }

            // toggle menu on bars click
            $( 'body' ).on( 'click', '[data-toggle="menu"]', function () {
                $( this ).find( 'span' ).toggleClass( 'in' );
                $( '#pageNavigation' ).fadeToggle( 'fast' );
            } );

            // reset menus by clicking on body
            $( 'body' ).on( 'click', function( event ) {
                if ( event.target.id == 'pageNavigation' || 
                     $( event.target ).closest( '#pageNavigation' ).length || 
                     event.target.id == 'toggleMenu' || 
                     $( event.target ).closest( '#toggleMenu' ).length ) {
                         
                    return;
                }
                else {
                    $( '[data-toggle="menu"]' ).find( 'span' ).removeClass( 'in' );
                    $( '#pageNavigation' ).fadeOut( 'fast' );
                }
            } );
        }
    };
    
    return nhw;
    
} )( nhwJS || {}, jQuery );