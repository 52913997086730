var nhwJS = ( function( nhw ) {
    'use strict';
    
    var _debug = false;
    var _flw = [
        'Of course it’s evil, kill it!',
        'It can’t talk to us like that!',
        'What do you mean, how many hit points do I have?',
        'Was that thunder, or were you rolling damage?',
        'No really. I can do this.',
        'Okay, if I max out this round and win initiative next round, maybe…',
        'I open the coffin…SLOWLY.',
        'Boost me up.',
        'You mean it was a GOOD dragon?',
        'I’ll steal the 20+ level mage’s pouch.',
        'What the hell, there’s six of us and only five type VI demons.',
        'A wish? Okay, genie, make me a ham sandwich.',
        'Hey, all I need is a two or better to save versus poison.',
        'YO! Grendel! Your momma wears combat boots!',
        'I dunno what a tarrasque is, but it can’t be TOO tough.',
        'What do you mean, the dragon wakes up?',
        'Wait! What do&nbsp;deathspells do?',
        'Go ahead and drink it.',
        'I’ll never surrender.',
        'It was a joke.',
        'Hey guys, where are you?',
        'I mix the potions and drink.',
        'There’s no such thing as a bottomless pit. Everybody knows that.',
        'Featherball! I mean, featherrrr……..',
        'Do you realize what you just did?',
        'Tell me this is an illusion.',
        'What do you mean, my spell expired.',
        'I’ll cast Fireball.',
        'Let’s go in.',
        'Let’s not go in.',
        'I follow them.',
        'I stab the dragon and tell it to get off me.',
        'I drink the bottle marked POISON on the off-chance that it’s the extra-healing potion.',
        'I kill it.',
        'No problem.',
        'Let me handle this.',
        'Whaddya mean, a pentagram only has FIVE sides?',
        'What a useless scroll. It just says, HASTUR HASTUR HASTUR over and over again…',
        'I though YOU brought the food!',
        'Why is your torch flame turning blue?',
        'AAAAAAAAAAAAAAAAAAAAAAAAHHHHHHH!!!',
        'Trust me.',
        'I never get lost.',
        'He hit me for HOW MUCH?????',
        'They’re only kobolds!',
        'Hey, this chest just bit me!',
        'I try to move silently in plate armor…',
        'I didn’t find any traps!',
        'Wonder what this button does?',
        'Don’t worry, he’s probably just first level.',
        'This 250′ wall has so many holes, it should be easy to climb.',
        'I’ll just close my eyes and walk up to the dracolisk holding up my mirror.',
        'Can I eat this green slime?',
        'What’s your alignment?',
        'My character WANTS to go out in a blaze of glory.',
        'I’ll just walk up to the dragon invisibly.',
        'Why is this man speaking in sign language?',
        'This type of undead can’t drain levels.',
        'I’ll open the door, sneak up on him from behind and backstab him!',
        'We’re in luck! The dragon is sleeping.',
        'That’s only a statue.',
        'There’s no trap on the door, so let’s open it.',
        'I have this dungeon at home, I know where everything is!',
        'Don’t worry, the DM won’t hose me.',
        'The DM’s an idiot.',
        'We’ll untie the prisoners and lock them in the closet.',
        'I see HOW MANY wights?!',
        'It was only the wind.',
        'Don’t worry, wyverns don’t attack unless they’re provoked.',
        'You watch the door, I’ll take out the Gas Spore (Beholder) that’s guarding the treasure.',
        'A Nightmare, huh? I’ll attack for one round and prepare to run.',
        'I’ll take off my armor so I’m silent and slip past the dragon.',
        'They need a twenty to hit me, I’m invincible.',
        'I throw a rock at the eight-legged lizard to get it’s attention.',
        'Who’s the bitch with the spiders?',
        'We killed all monsters on this level.',
        'I’ve been here before. There are no traps in this section.',
        'Well …, I’ll touch it again.',
        'I’ll scout ahead.',
        'I attempt to disbelieve.',
        'I know if I draw a card I’ll get the VOID.',
        'My first arrow MISSED the magic-user pointing at me? OK, I shoot again!',
        'Where’d that thief go now?',
        'Hey guys…wait up.',
        'Trap? What trap?',
        'So what?',
        'Ya know, since our druid’s been so obnoxious, it would probably serve him right if we set his precious forest on fire.',
        'OK, O Mighty Odin, as long as you’re not gonna answer my prayers, I’m gonna tell ya what I REALLY think of ya!',
        'Oops, I spilled flaming oil on my beard. I’d better wash it off after we kill this fire lizard.',
        'Well, we know he’s LAWFUL evil, so he should keep his word when he promised not to betray us.',
        'So I’m safely across the pit? Whew! For a minute there I was worried that you might remember my encumbrance penalties.',
        'Thank God!! A hobgoblin camp up ahead! Maybe they can help heal our wounded!',
        'Don’t worry! The chances of me blowing a climb walls roll twice, at my level, are infinite small.',
        'Well, *I* trust our party thief, and if he says this door isn’t trapped, that’s good enough for me.',
        'So that giant fell into the pit? I’ll jump over it and get his treasure.',
        'Me first. Me first.',
        'Try me, sh*t breath!',
        'Oops, sorry…didn’t mean to disturb you.',
        'Come on, we haven’t found any traps so far.',
        'Diamonds … Gold… Saphires!!! Terry! Terry, we’re rich, we’re rich, we’re fabulously wealthy!!!! Terry…Terry…??',
        'Let’s walk this way.',
        'Hey folks, follow me, I remember the way to the dungeon exit.',
        'I never get to have any fun!',
        'You mean they get to use the critical hit chart too?',
        'Hey, I know a dragon when I see one.',
        'What do you mean the whole room we’re in detects as a trap?',
        'Hey you! Frost Giant! How’s the weather up there?',
        'Just watch, I bet I get the one item that’s cursed.',
        'I’m invincible!',
        'A sign labeled ‘pit’? I walk up to it.',
        'No problem. That’s easy!',
        'Hah! I’m not dead yet. I still have five hit points.',
        'I don’t care. I have a Ring of Regeneration.',
        'Yeah, I know it’s dangerous, but think of the experience points.',
        'I stand right underneath the Fire Giant and point my wand straight up.',
        'I wonder what’s in here?',
        'He wouldn’t try that trick again!',
        'Just because you’re a dragon doesn’t mean you can push ME around.',
        'What do you mean trolls regenerate!?!',
        'Here kitty, kitty, kitty…',
        'I’ll open it.',
        'It seems easy enough.',
        'I think he can be trusted.',
        'Those noises are probably nothing.',
        'I’ll pull the lever.',
        'Money!',
        'Magic is for wimps.',
        'Oh. He’ll miss. Just look at my AC.',
        'Hey, where’d all the big spiders come from?',
        'AGAIN!?!?!',
        'I’ll use my taunt skill.',
        'Your mother was a Gully Dwarf.',
        'My God will protect me.',
        'You don’t look like a mage!',
        'It’d be stupid to trap this!',
        'Here, hold this rope while I go down.',
        'Well, if you didn’t belch, who did?',
        'I know an illusion when I see one.',
        'There’s a smell of gas, huh? Well, my lantern is hooded. It ought to be safe.',
        'I cast a lightning bolt at the ochre jelly.',
        'Lightning bolts don’t ricochet off stone walls, do they?',
        'A ballista? What’s that? How many dice of damage does it do?',
        'So what if he calls the guard? A backwater town like this can’t have a very big militia.',
        'What do you mean, ‘Your wand ran out of charges’?',
        'Oh these, I’ve fought them before…”=',
        'He looks like a sunburnt elf?',
        'All that noise we heard and there’s only one drow here?',
        'You’d have to be a GOD to smile after that hit!',
        'Take out a Beholder’s eyes, and Bingo!',
        'I can’t possibly miss…',
        'I go through the door… Wait, I check for traps!',
        'Don’t be silly. That kind of monster NEVER follows you.',
        'Is this one really able to breath fire?',
        'What do you mean, ‘How many hit points do I have RIGHT NOW??',
        'Come on, EVERY evil wizard’s tomb has a way out!',
        'It can’t be a beholder, we’re only first level!',
        'When nobody’s looking, I go back to get some more gems.',
        'It’s an illusion. No spell can reshape the side of a mountain like that. I disbelieve and walk off the 500’ cliff.',
        'He’s only an ordinary 15th level magic user.',
        'CLICK! What do you mean, ‘The trap WASN’T armed.’? What was that Remove Traps roll for anyway!?',
        'How was I to know that that orc would tell the truth about us not wanting to come in here!',
        'What do you mean, ‘Green slime ain’t always green.’?',
        'Nah…the game’s just started… he wouldn’t put a fatal death trap in yet.',
        'You don’t get Humanoid 8th level wizards. He’s only bluffing.',
        '(To Angry Red Dragon) Did anyone tell you you had bad breath?',
        'There’s HOW many Githyanki sleeping bunks in this chamber?',
        'A red WHAT swoops out of the sky at us?',
        'I’ll swim across.',
        'I throw the rock into the dark cave.',
        'I run down the hallway alone.',
        'I’ll use the wand of wonder.',
        'I want to check out the magic tome.',
        'Hmm…I’ll try Chain Lightning.',
        'Ok, the dragon’s asleep. You guys wait back here with bows and stuff. Getafix and I will go up in front of it and cast light on it’s eyes to blind him, then we’ll blow his brains out with psionics.',
        'Garth, you be the anchor. I tie the rope around myself, take the slack [700′] and jump in.',
        'There’s only ten&nbsp;kobolds and there’s eight of us. Attack!',
        'I dive through the fire.',
        'Follow those lights!',
        'There’s a company of 100 barbarians guarding the pass.&nbsp;‘I don’t think they want us to cross these mountains.’ ‘So what?’',
        'It’s only a naga.',
        'I’m not going to waste spells on THEM!',
        'That’s right, I’m going to polymorph into a pergrine falcon and attempt to land on the back of one of the 12 griffons flying above us.',
        'I think we can take it down.',
        'There only a pack of kobolds.',
        'Does a three save?',
        'C’mon guys – he can’t kill all of us!',
        'I use animal empathy to calm the charging Triceratops.',
        'There’s only three of them. Watch the one that looks like Death, though.',
        'Just because you can breathe fire doesn’t mean you can push *us* around.',
        'Hmm…how do we know you are the REAL Angel of Death?',
        'Hey, it’s only a black dragon, a vampire, and a lich…. and we’ve got a horn of bubbles!',
        'I sneak up to the Lich and pick its pockets.',
        'This is a wimp dungeon.',
        'What does this lever do?',
        'If I were you, Demon, I would sit back down!',
        'That purple robe really clashes with your burning eyes…',
        'Bow to a Demon? Never!',
        'Elminster, you old fart, I thought you were really mad for a minute.',
        'What do you mean I turn into a bug?',
        'Oh, please! Vampires have so many weaknesses, you can’t help but kill them!',
        'What do mean feather fall wears off?',
        'Okay, we’ll attack the small boulette first.',
        'What do you mean, the Wall of Ice vanishes?',
        'They CAN’T have initiative!',
        'You’ve got 80 hit points; YOU open the treasure chest.',
        'The Hall of Blades? Hey, I’ve got an 18 dex.',
        'What do you mean my axe bounces off him? What’s Stoneskin do anyway?',
        'C’mon guys… how bad could it be?!',
        'Cmon guys, it was only a rumor, there’s nothing here.',
        'So what, I have the artifact!',
        'Ok, so theres a few more of them.',
        'But I just got a little prick!',
        'Did he say he had Plate Mail +5? I stop running and fight him!',
        'Hey, do you guys think that this might just be an illusio… (whack)',
        'I pick the lock on the magic shop window.',
        'We charge!'
    ];
    var _test = [];
    var _famousLastWord = '';
    
    nhw.famousLastWords = {
    	init: function() {
            if ( _debug ) {
                console.log( 'Initializing: nhwJS.famousLastWords.init' );
            }
            
            // pick radom flw from array
            _famousLastWord = _flw[ Math.floor( Math.random() * _flw.length ) ];
    
            // write words to html
            $( '.page-footer__last-words-inner blockquote' ).text( _famousLastWord );
        }
    };
    
    return nhw;
    
} )( nhwJS || {}, jQuery );